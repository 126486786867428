import React, { FunctionComponent, ReactElement } from 'react';
import { SEO, GeneralJumbotron, Sponsors } from '../components';
import { MainLayout } from '../layouts';

const SponsorsPage: FunctionComponent = (): ReactElement => {
    return (
        <MainLayout>
            <SEO title="PCH | Sponsors"/>
            <GeneralJumbotron
                title="Sponsors"
                backgroundImageURL=""
                backgroundText="Sponsors"
            />
            <Sponsors/>
        </MainLayout>
    );
}

export default SponsorsPage;
